<template>
  <div>
    <div class="text-center ma-2">
      <table >
        <tr>
          <td>
             <v-btn color="indigo" dark @click="dialog = true">اصدار احصائية</v-btn>
          </td>
          <td>
             <v-btn color="indigo" dark @click="dialogStage = true">أوائل المراحل</v-btn>
          </td>
        </tr>
      </table>
   
    </div>
     <v-data-table
            :headers="statisticsHeader"
            :items="statistics"
             class="elevation-1"
            :loading="isGettingData"
             loading-text="يتم جلب البيانات ...الرجاء الانتظار"
          >
        <template v-slot:item.action="{ item }">
       

        <v-btn rounded color="success" dark  @click="showDetail(item)" :loading="isClickDetail" class="ml-2">
         عرض التفاصيل
        </v-btn>
     <v-btn rounded color="error" dark  @click="deleteStatistics(item)">
         حذف
        </v-btn>
     
      </template>

     </v-data-table>

    <v-dialog v-model="dialog" width="350px">
      <v-card>
        <v-card-title class="accent white--text darken-2"
          >اصدار احصائية</v-card-title
        >
        <v-container>
          <v-row justify="center">
            <div>
              <v-card flat width="300px" class="mx-auto">
                <v-card-text>
                  <v-form ref="form" v-model="valid">
                    <v-row class="mb-2">
                      <v-col cols="12">
                        <v-select
                          v-model="newStatic.month"
                          :items="[
                            'كانون الثاني',
                            'شباط',
                            'آذار',
                            'نيسان',
                            'أيار',
                            'حزيران',
                            'تموز',
                            'آب',
                            'أيلول',
                            'تشرين الأول',
                            'تشرين الثاني',
                            'كانون الأول',
                          ]"
                          placeholder="الشهر"
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="mb-2">
                      <v-col cols="12">
                        <v-autocomplete
                          ref="classes"
                          v-model="newStatic.classId"
                          :items="classes"
                          placeholder="اختر الشعبة "
                          required
                          item-text="name"
                          item-value="id"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </div>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="releaseStatistics"
            :loading="isClick"
            >اصدار</v-btn
          >
          <v-btn text @click="dialog = false">الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStage" width="750px">
      <v-card>
        <v-card-title class="accent white--text darken-2"
          >أوائل المراحل</v-card-title
        >
        <v-container>
          <v-row justify="center">
            <div>
              <v-card flat width="700px" class="mx-auto">
                <v-card-text>
                  <v-form ref="form" v-model="valid">
                    <v-row >
                      <v-col cols="6" md="4">
                        <v-select
                          v-model="viewStatis.month"
                          :items="Month"
                           item-text="name"
                           item-value="num"
                          placeholder="الشهر"
                          required
                        ></v-select>
                       </v-col >
                        <v-col cols="6" md="4">
                        <v-select
                          v-model="viewStatis.stage"
                          :items="[
                            'الاعدادية',
                            'الثانوية_علمي',
                            'الثانوية_أدبي',
                          ]"
                          placeholder="المرحلة"
                          required
                        ></v-select>
                        </v-col>
                        <v-col cols="6" md="4">
                           <v-btn color="indigo" dark @click="getTopThree">حساب</v-btn>
                        </v-col>
                     
                    </v-row>

                    <v-row class="mb-2">
                      <v-col cols="12">
                       <v-data-table
            :headers="topThreeHeader"
            :items="topThrees"
            item-key="id"
            class="elevation-1"
              :loading="loadingTopThree"
          ></v-data-table>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </div>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialogStage = false">اغلاق</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDetail"  fullscreen>
            <v-card>
                <v-toolbar
          dark
          color="primary"
        >
        
          <v-toolbar-title>{{detailHeader}} </v-toolbar-title>
          <v-spacer></v-spacer>
            <v-btn
            icon
            dark
            @click="dialogDetail = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar>
     
        <v-container>
          <v-row justify="center">
            <div>
              <v-card flat width="1050px" class="mx-auto">
                <v-card-text>
                   <v-row class="mb-2">
                      <v-col cols="12">
                     <export-excel
              class="btn  btn-default outlined fab  ma-2"
              :data="statisticsDetails"
              :fields="exportHeaders"
              worksheet="My Worksheet"
              name="statistic.xls"
            >
              <v-btn outlined color="green"> تصدير البيانات</v-btn>
            </export-excel>
             </v-col>
                  </v-row>
                 <v-row class="mb-2">
                      <v-col cols="12">
                       <v-data-table
                        :headers="mainDetailHeader"
                         :items="statisticsDetails"
                           class="elevation-1"
                       ></v-data-table>
                      </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { APIService } from "../../services/apiServices";
const apiService = new APIService();
export default {
  data() {
    return {
      dialog: false,
      loadingTopThree:false,
      isGettingData :true,
      dialogStage:false,
      isClickDetail:false,
      dialogDetail:false,
      detailHeader:"",
      isClick: false,
      valid: false,
      newStatic: {
        month: "",
        classId: 0,
      },
      viewStatis:{
           month: 0,
           stage:""
      },
      statisticsHeader:[
         {
          text: "الشعبة",
          align: "start",
          value: "monthName",
        },
         {
          text: "الشهر",
          align: "start",
          value: "className",
        },
         {
          text: "المجموع العام",
          align: "start",
          value: "totlaMark",
        },
        { text: "العمليات", value: "action", sortable: false },
      ],
      mainDetailHeader: [
        {
          text: "الاسم",
          align: "start",
          value: "studentName",
        },
        {
          text: "المجموع",
          align: "start",
          value: "sum",
        },
        { text: "النسبة المئوية", value: "percentage" },
      ],
     topThreeHeader:[
        {
          text: "الاسم",
          align: "start",
          value: "studentName",
        },
         {
          text: "الشعبة",
          align: "start",
          value: "className",
        },
         {
          text: "المجموع",
          align: "start",
          value: "sum",
        },
         {
          text: "النسبة المئوية",
          align: "start",
          value: "percentage",
        },
     ],
     exportHeaders:{
           Name: "studentName",
      "Sum": "sum",
      Percentage: "percentage",
     },
     statistics: [],
     statisticsDetails:[],
     topThrees:[],
      classes: [],
      Month:[
      {num:1,name:'كانون الثاني'},
      {num:2,name:'شباط'},{num:3,name:'آذار'},{num:4,name:'نيسان'},
      {num:5,name:'أيار'},{num:6,name:'حزيران'},{num:7,name:'تموز'},
      {num:8,name:'آب'},{num:9,name:'أيلول'},{num:10,name:'تشرين الأول'},
      {num:11,name:'تشرين الثاني'},{num:12,name:'كانون الأول'}],
    
    };
  },
  methods: {
    getStatistics() {
       this.isGettingData = true;
      apiService
        .getStatistics()
        .then((response) => {
          this.statistics = response.statistics;
          this.isGettingData = false;
        })
        .catch(() => {
          this.statistics = [];
          this.isGettingData = false;
        });
    },
    getClasses() {
      apiService
        .getClasses()
        .then((response) => {
          this.classes = response.classes;
        })
        .catch((error) => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
        });
    },
    getTopThree()
    {
      this.loadingTopThree=true;
       apiService
        .GetTopThrees(this.viewStatis)
        .then((response) => {
          this.topThrees = response.topThrees;
          this.loadingTopThree=false;
        })
        .catch((error) => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.loadingTopThree=false;
          this.failed();
        });
    },
    showDetail(item)
   {
     this.detailHeader ="احصائيات " +item.className+" للشهر " +item.monthName;
     this.isClickDetail=true;
       apiService
         .GetStatisticsDetails(item.monthNum,item.classId)
        .then((response) => {
          this.statisticsDetails = response.details;
          this.dialogDetail=true;
           this.isClickDetail=false;
        })
        .catch((error) => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
           this.isClickDetail=false;
        });
},
 deleteStatistics(item) {
      var statObject = {
        Id: item.id,
      };
      if (confirm("هل تريد بالتأكيد حذف الاحصائية")) {
        setTimeout(() => {
          apiService
            .deleteStatistics(statObject)
            .then(() => {
              this.getStatistics();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
      }
    },

    releaseStatistics() {
      if (this.newStatic.month != "" && this.newStatic.stage != "") {
        this.isClick = true;

        setTimeout(() => {
          apiService
            .releaseStatistics(this.newStatic)
            .then((response) => {
              if (response != "") {
                this.newStatic = {
                  id: 0,
                  month: "",
                  stage: "",
                };
                this.dialog = false;
                this.getStatistics()
              } 
              else {
                this.isClick = false;
              }
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            })
            .finally(() => {
              this.isClick = false;
            });
        }, 3000);
      }
    },
  },
  created() {
    this.getStatistics();
  },
  watch: {
    dialog(val) {
      if (val === false) {
        this.getStatistics();
      } else this.getClasses();
    },
  },
};
</script>
